@import "styles/_variables";
@import "styles/_mixins";

.map {
  @include subComponentsStyle();
  height: 85%;
  display: flex;
  margin: 0 0 0 40px;
  min-width: 1000px;
  .map-kakao {
    width: 40%;
    margin: 0 40px 0 0;
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map-text {
    width: 50%;
    display: flex;
    align-items: center;
    .map-grid {
      padding-top: 45%;
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 8fr;
      grid-template-rows: repeat(6, 1fr);
      align-items: center;
      .address {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 58px;
        color: #333333;
        &.first {
          grid-column: 1/-1;
          grid-row: 1/2;
        }
        &.second {
          grid-column: 1/-1;
          grid-row: 2/3;
        }
        &.secondFirst{
          grid-column: 1/-1;
          grid-row: 3/5;
        }
        &.secondSecond{
          grid-column: 1/-1;
          grid-row: 4/6;
        }
      }
      .info {
        &.title {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 29px;
          color: #333333;
        }
        &.content {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 37px;
          color: #333333;
        }
      }
    }
  }
}
