@import "styles/_variables";
@import "styles/_mixins";
.m-map{
  // border:4px red solid;
  box-sizing:border-box;
  height:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  .m-map-naver {
    border:1px gray solid;
    box-sizing:border-box;
    width: 90%;
    height:70%;
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .m-map-text {
    // border:4px red solid;
    box-sizing:border-box;
    width: 90%;
    padding:5% 0%;
    >div{
      margin:2% 0;
    }
    >div:nth-child(-n+2){
      font-weight:bold;
    }
    >div:nth-child(3){
      margin-top: 5%;
    }
  }
}