@import "styles/_variables";
@import "styles/_mixins";

.hm3-body-wrapper {
  position: relative;
  // border:4px green solid;
  box-sizing: border-box;

  // height: 128%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .hm3-box1 {
    margin: 10% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #333333;
    font-size: 16px;
  }

  .hm3-box2 {
    display: flex;
    justify-content: center;

    >img {
      width: 100%;
    }
  }

  .hm3-box3 {
    margin: 5% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: #333333;
  }

  .hm3-box4 {
    margin: 10% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;

    color: #333333;
  }

  .hm3-box5 {
    margin: 5% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    color: #333333;
  }

  .hm3-footer {
    // position: absolute;
    // bottom:0;
    // left:0;
    // right:0;
    background: #0032A0;
    padding: 5% 6% 8% 6%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    color: rgba(255, 255, 255, 0.6);
    // border:1px red solid;
  }
}