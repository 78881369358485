@import "styles/_variables";
@import "styles/_mixins";


.home_mobile {
  border:4px blue solid;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // justify-content: stretch;
  overflow-y: auto;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    width: 100%;
  }
}

.home {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  position: relative;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;

  .svgtest {
    position: absolute;
    bottom: 0vh;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    .path {
      stroke-dasharray: calc(4150 * 2);
      stroke-dashoffset: calc(4150 * 2);
    }

    &.activated1 {
      .path {
        animation: dash1 3s linear forwards;
      }

      @keyframes dash1 {
        to {
          stroke-dashoffset: 4000;
        }
      }
    }

    &.activated2 {
      .path {
        animation: dash2 2s linear forwards;
      }

      @keyframes dash2 {
        to {
          stroke-dashoffset: 0;
        }

        from {
          stroke-dashoffset: 4150;
        }
      }
    }

    .squiggle {
      stroke-dasharray: 450;
      stroke-dashoffset: 450;

      &.trigger {
        @include animation(2s, 3s, 1, forwards, draw);
      }
    }
  }

  .cardWrapper {
    // border: 1px red solid;
    position: relative;
    width: 10vw;
    height: 10vw;
    box-sizing: border-box;
    top: 20vh;
    padding-left: 100vw;

    &.trigger {
      @include animation(0s, 3s, 1, forwards, slidein);
    }

    .cardContainer {
      position: absolute;
      // border: 1px blue solid;
      display: flex;
      white-space: nowrap;

      .card {
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          img {
            height: 80vh;
          }
        }

        &:nth-child(2) {
          img {
            height: 70vh;
          }
        }

        &:nth-child(3) {
          img {
            height: 80vh;
          }
        }

        img {
          -webkit-filter: brightness(100%);
        }
      }
    }
  }

  .emptyBackground {
    width: 100%;
    height: 100%;

    h2 {
      position: absolute;
      bottom: 10%;
      width: 100vw;
      font-family: "Montserrat";
      font-style: bold;
      font-weight: 900;
      padding-left: 8vw;
      font-size: 5vw;
      line-height: 6vw;
      color: black;
      overflow: hidden;
    }
  }
}