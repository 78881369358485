@import "styles/_variables";
@import "styles/_mixins";

.handycap {
  @include subComponentsStyle();
  padding-bottom: 0px;
  display: flex;
  height: 85%;
  min-height: 600px;
  min-width: 1000px;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: left 0% top 100%;
  position: relative;

  &.page1 {
    overflow: hidden;
    height: 90vh;

    .handycap-textbox {
      position: absolute;
      top: 60%;
      left: 60%;

      >div {
        &:nth-child(1) {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 55px;
          color: #00656e;
        }

        &:nth-child(2) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: 300;
          font-size: 26px;
          line-height: 38px;
          color: #333333;
        }

        &:nth-child(3) {
          display: flex;

          >div {
            margin: 30px 15px 0px 0px;
            width: 137px;
            height: 55px;
            left: 1214px;
            top: 833px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 27.5px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background: #0032A0;
              color: white;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &.page2 {
    height: 90vh;
    background-size: 35%;
    background-position: left 5% bottom 0%;

    .handycap-textbox {
      position: absolute;
      top: 30%;
      left: 40%;
      width: 600px;

      >div {
        &:nth-child(1) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          color: #858481;
        }

        &:nth-child(2) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 43px;

          color: #00656e;
        }

        &:nth-child(3) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 38px;
          color: #858481;
          margin-top: 20px;
        }
      }
    }
  }

  &.page3 {
    height: 90vh;
    background-size: 35%;
    background-position: right 5% bottom 0%;

    .handycap-textbox {
      position: absolute;
      top: 30%;
      left: 15%;
      width: 600px;

      >div {
        &:nth-child(1) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          color: #858481;
        }

        &:nth-child(2) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 43px;

          color: #00656e;
        }

        &:nth-child(3) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 38px;
          color: #858481;
          margin-top: 20px;
        }
      }
    }
  }

  &.page4 {
    height: 90vh;
    background-size: 35%;
    background-position: left 5% bottom 0%;

    .handycap-textbox {
      position: absolute;
      top: 40%;
      left: 40%;
      width: 600px;

      >div {
        &:nth-child(1) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          color: #858481;
        }

        &:nth-child(2) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 43px;

          color: #00656e;
        }

        &:nth-child(3) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 38px;
          color: #858481;
          margin-top: 20px;
        }
      }
    }
  }
}