@import "styles/_variables";
@import "styles/_mixins";

.m-call>div>.m-call-content {
  color: black;
  font-weight: 400;
  font-family: "Noto Sans KR";
  height:40vw;
}

.m-call {
  // border:5px red solid;
  box-sizing: border-box;
  width: 100%;
  padding: 10% 5%;
  height: 100%;

  input,
  textarea {
    // background: rgba(221, 221, 221, 0.3);
    background: #F9F9F9;
    // border: 2px rgba(0, 0, 0, 0.2) solid;
    border: 1px #DCDCDC solid;
    border-radius:0px;

    // margin:0;
    padding: 1%;
    // border:1px red solid;
    color: black;
    font-weight: 400;
    font-family: "Noto Sans KR";
  }

  >div {
    // border:1px red solid;
    display: flex;
    justify-content: space-between;
    margin: 2% 0%;

    >span {
      box-sizing: border-box;
      width: 25%;
      font-weight: bold;
    }

    >input {
      width: 73%;
    }

    >textarea {
      width: 73%;
      height: 10vw;
      resize: vertical;
      color: black;
      font-weight: 400;
      font-family: "Noto Sans KR";
    }
  }

  >div:nth-last-child(3) {
    // border:1px blue solid;
    justify-content:flex-end;
    >textarea {
      width: 72.5%;
      height: 30vw;
      resize: vertical;
      color: #848484;
    }
  }

  >div:nth-last-child(2) {
    justify-content: flex-end;

    // border:1px red solid;
    // justify-content:flex-start;
    // margin:6% 0%;
    // padding:40%;
    >div {
      // border:1px blue solid;
      box-sizing: border-box;
      width: 78%;
      // overflow:hidden;
      // white-space:nowrap;
      display: flex;

      >span:nth-child(1) {
        // border:1px cyan solid;
        border: 2px #CCCCCC solid;
        background: #F9F9F9;
        color: #CCCCCC;
        width: 4vw;
        height: 4vw;
        // margin-right: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }

      >span:nth-child(2) {
        // border:1px green solid;
        margin-left:10px;

      }
    }
  }

  >div:nth-last-child(1) {
    display: flex;
    justify-content: center;

    >div {
      // border:1px red solid;
      background-color: #0032A0;
      color: white;
      width: 30%;
      height: 8vw;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }
}