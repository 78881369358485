@import "styles/_variables";
@import "styles/_mixins";
.history {
  @include subComponentsStyle();
  display: flex;
  padding-top: 20vh;
  align-items: flex-start;
  justify-content: center;
  .contents {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center center;
  }
}
