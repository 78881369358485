.subrnd3 {
  overflow: hidden;
  position: absolute;
  top: -20%;
  width: 100%;

  >div {
    position: absolute;
    font-size: 10px;

    &:nth-child(1) {
      font-size: 12px;
      font-weight: 500;
      padding-left: 10px;
      border-left: 1px black solid;
      top: 32%;
      left: 10%;
    }

    &:nth-child(2) {
      font-size: 12px;
      font-weight: 500;
      padding-left: 10px;
      border-left: 1px black solid;
      top: 44%;
      left: 10%;
    }

    &:nth-child(3) {
      font-size: 12px;
      font-weight: 500;
      top: 50%;
      left: 14%;
    }

    &:nth-child(4) {
      font-size: 12px;
      font-weight: 500;
      top: 82%;
      left: 10%;
    }

    &:nth-child(5) {
      font-size: 10px;
      font-weight: 500;
      top: 90%;
      left: 10%;
      width: 78%;
      opacity: 0.6;
    }

    &:nth-child(6) {
      display: flex;
      justify-content: space-between;
      width: 80%;

      font-size: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 139.8%;
      bottom: 1.5%;
      left: 10%;

      >div {
        display: flex;
        align-items: center;

        >div:nth-child(1) {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;

          color: #121212;
        }

        >div:nth-child(2) {
          margin-left: 20px;
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 139.8%;
          color: #121212;
        }

        >img {
          width: 40px;
        }
      }
    }
  }

  .back-btn {
    // border: 1px red solid;
    // box-sizing: border-box;
    z-index: 1;
    position: absolute;
    top: 8.5%;
    left: 10%;
    width: 10vw;
    height: 10vw;
  }

  .subrnd3-img1 {
    margin-left: 10%;
    position: absolute;
    top: 7%;
    width: 80%;
  }

  .subrnd3-img2 {
    width: 100%;
  }
}