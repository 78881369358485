@import "styles/_variables";
@import "styles/_mixins";

.trafficsafety {
  @include subComponentsStyle();
  padding-bottom: 0px;
  display: flex;
  height: 85%;
  min-height: 600px;
  min-width: 1000px;
  // border: 1px red solid;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: left 0% top 100%;
  position: relative;

  &.page1 {
    overflow: hidden;
    height: 90vh;

    .trafficsafety-textbox {
      // border: 1px red solid;
      position: fixed;
      top: 60%;
      left: 5%;
      z-index: 2;

      >div:nth-child(1) {
        display: flex;
        width: 30vw;

        >img {
          // border: 1px red solid;
          flex-shrink: 0;
        }

        >div {
          // border: 1px blue solid;
          padding-left: 1%;
          display: flex;
          flex-direction: column;
          justify-content: center;


          >div {
            &:nth-child(1) {
              font-family: "Noto Sans KR";
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 38px;
              color: #333333;
            }

            &:nth-child(2) {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: bold;
              font-size: 45px;
              line-height: 55px;
              color: #1976D3;
            }
          }
        }
      }

      >div:nth-child(2) {
        margin-top: 5%;
        // border: 1px blue solid;
        height: 3vw;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;

        >span {
          border: 1px #1976D3 solid;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          color: #1976D3;
          font-size: 1.1vw;
          user-select: none;

          &:hover {
            background-color: #1976D3;
            color: white;
          }
        }
      }

      // >div:nth-child(2) {
      //   border: 1px blue solid;

      //   &:nth-child(1) {
      //     border: 1px red solid;
      //     display: flex;

      //     >div {
      //       margin: 30px 15px 0px 0px;
      //       width: 137px;
      //       height: 55px;
      //       left: 1214px;
      //       top: 833px;
      //       border: 1px solid #000000;
      //       box-sizing: border-box;
      //       border-radius: 27.5px;
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;

      //       &:hover {
      //         background: #0032A0;
      //         color: white;
      //         cursor: pointer;
      //       }
      //     }
      //   }
      // }
    }
  }

  &.page2 {
    // border: 4px red solid;
    // box-sizing: border-box;
    height: 90vh;
    background-size: 35%;
    background-position: left 5% bottom 0%;
    position: relative;


    .trafficsafety-text-img-box {
      position: absolute;
      // border: 4px yellow solid;
      // height: 10vw;
      width: 55vw;
      top: 15vw;
      right: 0;

      // border: 4px blue solid;
      box-sizing: border-box;

      >img {
        width: 100%;
      }

      >div {
        font-size: 1.2vw;
      }

      .box-text1 {
        // border: 5px red solid;
        box-sizing: border-box;
        // width: 100%;
        // height: 100%;
        position: absolute;
        top: 34%;
        left: 24%;
        width:20%;
        >div{
          // border:1px blue solid;
          display:flex;
          justify-content:center;
        }
      }

      .box-text2 {
        // border: 5px red solid;
        box-sizing: border-box;
        // width: 100%;
        // height: 100%;
        position: absolute;
        top: 34%;
        left: 45%;
        width:20%;
        >div{
          // border:1px blue solid;
          display:flex;
          justify-content:center;
        }
      }
    }

    .trafficsafety-top-img {
      // border: 4px cyan solid;
      position: absolute;
      width: 37vw;
      top: 0;
      left: 0;
    }

    .trafficsafety-second-img {
      // border: 4px green solid;
      width: 25vw;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    >img:nth-child(2) {
      bottom: 0%;
      left: 0vw;
    }

    >img:nth-child(3) {
      top: 0vw;
      left: 0vw;
    }

    .trafficsafety-textonly {
      position: absolute;
      top: 66%;
      left: 50%;
      width: 600px;

      >div {
        // border: 1px red solid;
        font-size: 1.1vw;
        width: 25vw;
        color: #4A4A4A;
        line-height:1.5vw;
        display:flex;
        align-items:center;
        &:nth-child(2){
          // border:1px red solid;
          font-weight:bold;
          font-size:1.4vw;
          margin-top:10%;
          color:#1976D3;
          cursor:pointer;
        }
        >img{
          margin-left:1vw;
          border:1px transparent solid;
          &:hover{
            border:1px #1976D3 solid;
          }
        }
      }
    }
  }

  &.page3 {
    height: 90vh;
    background-size: 35%;
    background-position: right 5% bottom 0%;

    .handycap-textbox {
      position: absolute;
      top: 30%;
      left: 15%;
      width: 600px;

      >div {
        &:nth-child(1) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          color: #858481;
        }

        &:nth-child(2) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 43px;

          color: #00656e;
        }

        &:nth-child(3) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 38px;
          color: #858481;
          margin-top: 20px;
        }
      }
    }
  }

  &.page4 {
    height: 90vh;
    background-size: 35%;
    background-position: left 5% bottom 0%;

    .handycap-textbox {
      position: absolute;
      top: 40%;
      left: 40%;
      width: 600px;

      >div {
        &:nth-child(1) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          color: #858481;
        }

        &:nth-child(2) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 43px;

          color: #00656e;
        }

        &:nth-child(3) {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 38px;
          color: #858481;
          margin-top: 20px;
        }
      }
    }
  }
}