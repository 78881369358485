@import "styles/_variables";
@import "styles/_mixins";

.hm3-body-wrapper {
  // position: relative;
  // height:80%;
  // border: 4px red solid;
  box-sizing: border-box;

  // height: 188%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .hm3-box1 {
    position: relative;
    // border: 4px blue solid;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    >div:nth-child(1) {
      // border:1px red solid;
      display: flex;
      justify-content: flex-start;
      // height:10%;
      width: 90%;

      >div {
        // border:1px blue solid;
        // margin-right: 10%;
        margin: 10% 5% 4% 0;
      }
    }

    >table {
      border-collapse: collapse;
      // border:1px red solid;
      width: 90%;
      border-top: 2px black solid;

      th{
        height: 5vw;
        font-size: 4vw;
        font-family: "Noto Sans KR";
        height: 31px;
        font-weight:500;
        color: rgba(51, 51, 51, 1);

        &:nth-child(1) {
          // border:1px blue solid;
          width: 12%;
          text-align: center;
        }

        &:nth-child(2) {
          // border:1px blue solid;
          width: 68%;
          text-align: left;
          padding-left: 5%;
        }

        &:nth-child(3) {
          // border:1px blue solid;
          width: 20%;
          text-align: center;
        }
      }
      td {
        height: 5vw;
        font-size: 3.5vw;
        font-family: "Noto Sans KR";
        height: 50px;

        &:nth-child(1) {
          // border:1px blue solid;
          width: 12%;
          text-align: center;
          color: #333333;
        }

        &:nth-child(2) {
          // border:1px blue solid;
          width: 68%;
          text-align: left;
          padding-left: 5%;
        }

        &:nth-child(3) {
          // border:1px blue solid;
          width: 20%;
          text-align: center;
          color: #333333;
        }
      }

      tr {
        border-bottom: 0.5px rgba(0,0,0,0.2) solid;
        // border:1px blue solid;
      }


      >tbody {
        >tr {
          height: 5vw;
          border-bottom: 0.5px rgba(0,0,0,0.2) solid;
          >td {
            &:nth-child(1) {
              >div {
                display: flex;
                align-items: center;
                justify-content: center;
                // border:1px red solid;
                border-radius: 20px;
                height: 60%;
                color: white;
                font-weight: bold;
                background-color: #0032A0;
                font-family: "Noto Sans KR";
                font-size:14px;
              }
            }
          }
        }
      }
    }

    >div:nth-child(3) {
      display: flex;
      // border:1px blue solid;
      justify-content:flex-start;
      width: 80%;
      height: 8vw;
      margin: 3% 0 8% 0;
      >select:nth-child(1){
        margin:0;
        color: rgba(0, 0, 0, 0.4);
        -webkit-appearance: none;
        width:13vw;
        padding-left:5px;
        background:transparent;
        border:1px #0032A0 solid;
        border-right:0px;
        border-radius:0px;
        // -moz-appearance: none; 
        // appearance: none;
        // border:1px red solid;
        // background:transparent;
        // width:
        background-image:url(../../../../media/MOBILE/smmobile/contact/down-arrow.png);
        background-size: 30%;
        background-position-x: 95%;
        background-position-y:center;
        background-repeat: no-repeat;
      }
      >input:nth-child(2){
        margin:0;
        border:1px #0032A0 solid;
        border-radius:0px;
        max-width:40%;
      }
      >div {
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-child(n+3) {
          white-space: nowrap;
          // border:1px red solid;
          background-color: #0032A0;
          color: white;
          padding: 0 8px;
        }

        &:nth-child(4) {
          margin-left: 11px;
        }
      }
    }
  }

  .hm3-box2 {
    // border:4px red solid;
    width: 90%;
    margin: 10% 5%;

    >div:nth-child(1) {
      // border:1px blue solid;
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;

      color: #333333;
    }

    >div:nth-child(2) {
      margin-top: 2vw;
      font-weight: 300;
      font-size: 11px;
      line-height: 16px;

      color: #333333;
    }

    >div:nth-child(3) {
      margin-top: 6vw;
      padding: 2vw;
      background: #F9F9F9;
      border: 1px solid #CCCCCC;
      box-sizing: border-box;
      overflow: scroll;
      height: 50vh;
    }

    >div:nth-child(4) {
      // border:1px red solid;
      margin-top: 4vw;
      display: flex;
      align-items: center;

      >span:nth-child(1) {
        border: 2px #CCCCCC solid;
        background: #F9F9F9;
        color: #CCCCCC;
        width: 4vw;
        height: 4vw;
        margin-right: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }
    }

    >div:nth-child(5) {
      margin-top: 10vw;
      margin-bottom: 20vw;
      display: flex;
      justify-content: center;

      >span {
        // border:1px red solid;
        width: 30vw;
        height: 8vw;
        margin: 0 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        &:nth-child(1) {
          background-color: #ADAEAF;
        }

        &:nth-child(2) {
          background-color: #ADAEAF;
        }

        &:nth-child(3) {
          background-color: #0032A0;
        }
      }
    }
  }

  .hm3-box3 {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    // border: 5px blue solid;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    // z-index: 3;
    .hm3-box3-auth_process {
      width: 70vw;
      height: 40vw;
      background: white;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >div {
        // border: 1px red solid;

      }

      >input {
        display: flex;
        align-items: flex-end;
        border: 0px red solid;
        text-align: center;
        width: 80%;
        height: 40%;
        caret-color: transparent;
      }

      >input:focus {
        outline: none;
      }

      >input[type="password"] {
        color: #0032A0;
        font-size: 12vw;
        -webkit-text-security: disc;

        &::placeholder {
          -webkit-text-security: disc;
        }
      }
    }

    .hm3-box3-confirm_process {
      width: 70vw;
      height: 40vw;
      background: white;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >div {
        // border:1px red solid;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: center;

        color: #333333;

        &:nth-child(1) {
          height: 60%;
          width: 70%;
        }

        &:nth-child(2) {
          height: 30%;
          width: 100%;
          border-top: 2px solid #CFCFCF;

          >span {
            width: 25%;
            height: 6vw;
            // border:1px red solid;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0% 4%;

            &:nth-child(1) {
              background: #0032A0;
              color: white;
            }

            &:nth-child(2) {
              background: #E1E1E1;
              color: black;
            }
          }
        }
      }
    }
  }

  .hm3-box4 {
    // border:4px red solid;
    width: 80%;
    margin: 10% 10%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .recruit-select {
      
      margin-bottom: 2vw;
      width: 100%;
      height: 8vw;
      padding: 0 3%;
      // padding:0% 4%;
      font-size: 4vw;
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: normal;

      color: #333333;
    }

    .recruit-input {
      margin: 0;
      margin-bottom: 2vw;
      width: 100%;
      height: 8vw;
      padding: 0 3%;
      box-sizing: border-box;

      &::placeholder {
        opacity: 0.4;
      }

      font-size: 4vw;
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: normal;

      color: #333333;
    }

    .quill-container {
      // border: 10px green solid;
      // box-sizing: border-box;
      width: 100%;
    }

    .register-container {
      margin-top: 4vw;
      margin-bottom: 50vh;
      display: flex;
      justify-content: space-between;
      // border:5px red solid;
      width: 80%;
      // align-items: flex-start;
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;

      .register-btn-cancel {
        border: 1px #E1E1E1 solid;
        background: #E1E1E1;
        width: 30vw;
        height: 10vw;
        color: black;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .register-btn {
        border: 1px #0032A0 solid;
        background: #0032A0;
        width: 30vw;
        height: 10vw;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .edit-btn {
        border: 1px #adaeaf solid;
        background: #adaeaf;
        width: 30vw;
        height: 10vw;
        // padding: 1vw 6vw;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}