@import "styles/_variables";
@import "styles/_mixins";

.header2 {
  height: 4vw;
  font-family: "Noto Sans KR";
  font-weight: 300;
  font-size: 1.2rem;
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);

  @keyframes showup {
    to {
      opacity: 1;
    }

    from {
      opacity: 0;
    }
  }

  &.main {
    background: rgba(0, 0, 0, 0.3);

    &.turnBgWhite {
      @include animation(0s, 3s, 1, forwards, fadebgwhite);
    }
  }

  a {
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    img {
      grid-column: 1/2;
      grid-row: 1/2;
      height: 2vw;
      margin-left: 5vw;

      &:nth-child(1) {
        &.turnBlue {
          @include animation(0s, 3s, 1, forwards, fadeout);
        }
      }

      &:nth-child(2) {
        opacity: 0;

        &.turnBlue {
          @include animation(0s, 3s, 1, forwards, fadein);
        }
      }
    }
  }

  .logo-text {
    user-select: none;
    z-index: 1;
    border: 1px rgb(80, 77, 77) solid;
    border-radius: 4px;
    background: white;
    line-height: 1.5vw;
    font-weight: 600;
    width: 7vw;
    color: rgb(80, 77, 77);
    position: fixed;
    top: 3vw;
    left: 9vw;
    font-size: 0.6vw;
    text-align: center;
    font-family: "Montserrat";
    animation: showup 1s linear forwards;
  }

  ul {
    margin-right: 50px;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr repeat(4, 3fr) 1fr;
    grid-template-rows: 1fr;
    width: 30vw;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 1vw;
    text-align: center;
    position: relative;

    li {
      position: relative;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      font-family: "Noto Sans KR";
      font-weight: 400;

      &:nth-child(1) {
        grid-row: 1/2;
        grid-column: 2/3;
      }

      &:nth-child(2) {
        grid-row: 1/2;
        grid-column: 3/4;
      }

      &:nth-child(3) {
        grid-row: 1/2;
        grid-column: 4/5;
      }

      &:nth-child(4) {
        grid-row: 1/2;
        grid-column: 5/6;
      }

      a {
        text-decoration: none;
        text-underline-offset: 7px;
        color: black;

        &.main {
          color: rgba(255, 255, 255, 0.9);

          &.turnColorBlack {
            @include animation(0s, 3s, 1, forwards, turnColorBlack);
          }

          &:hover {
            font-weight: 500;
            color: white;
          }
        }

        &:hover {
          text-decoration: underline;
        }

        &.active {
          text-decoration: underline;
        }
      }
    }

    .header2-menu {
      font-size: 1vw;
      color: white;
      position: absolute;
      top: 2.5vw;
      z-index: 3;

      height: 3vw;

      background: #0032A0;
      border-radius: 300px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      cursor: pointer;

      a {
        color: white;
        text-underline-offset: 8px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        color: white;
        text-underline-offset: 7px;
        text-decoration: none;
      }

      &.about2 {
        width: 20vw;
        left: 0%;
      }

      &.service2 {
        width: 24vw;
        left: 5%;
      }

      &.project2 {
        width: 28vw;
        right: 5%;
      }

      &.contact2 {
        width: 23vw;
        right: 0%;
      }

      overflow: hidden;
      width: 207px;
    }
  }
}