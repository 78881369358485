@import "styles/_variables";
@import "styles/_mixins";

.app {
  // border: 1px red solid;
  // background: "gray";
  // box-sizing: border-box;
  height: 100vh;
  width: 100vw;
}

@include keyframe(draw) {
  to {
    stroke-dashoffset: 0;
  }
}

@include keyframe(turnBgWhite) {
  100% {
    background: rgba(250, 250, 250, 1);
  }

  0% {
    background: rgba(10, 52, 127, 0.98);
  }
}

@include keyframe(turnColorBlack) {
  100% {
    color: black;
  }

  0% {
    color: white;
  }
}

@include keyframe(fadeout) {
  100% {
    display: none;
    opacity: 0;
  }

  0% {
    opacity: 1;
  }
}

@include keyframe(fadein) {
  100% {
    opacity: 1;
  }

  0% {
    display: none;
    opacity: 0;
  }
}

@include keyframe(slidein) {
  100% {
    padding-left: 0vw;
  }

  0% {
    padding-left: 100vw;
  }
}

@include keyframe(slideup) {
  100% {
    margin-top: 0vw;
  }

  0% {
    margin-top: 10vw;
  }
}

@include keyframe(slideup_fadein) {
  100% {
    opacity: 1;
    margin-top: 0vw;
  }

  0% {
    opacity: 0;
    margin-top: 10vw;
  }
}

@include keyframe(menubgwhite) {
  100% {
    background: #0032A0;
  }

  0% {}
}

@include keyframe(fadebgwhite) {
  100% {
    background: rgba(255, 255, 255, 1);
  }

  0% {}
}

@include keyframe(bgSlidein2) {
  100% {
    left: 0vw;
  }

  0% {
    left: 100vw;
  }
}

@include keyframe(bgSlidein3) {
  100% {
    left: 0vw;
  }

  0% {
    left: -100vw;
  }
}

@include keyframe(bgSlidein4) {
  100% {
    left: 0vw;
  }

  0% {
    left: 100vw;
  }
}