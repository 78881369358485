@import "styles/_variables";
@import "styles/_mixins";

.hm3-body-wrapper {
  // position: relative;
  // border:4px red solid;
  box-sizing: border-box;

  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .hm3-box1 {
    // border:4px red solid;
    box-sizing: border-box;
    display: flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;

    .hm3-box1-content1 {
      margin-top:10vw;
      // border:5px blue solid;
      box-sizing:border-box;
      width:85%;
      position:relative;

      >img {
        width: 100%;
        // border: 4px red solid;
        box-sizing:border-box;
      }
      >div{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        // border:1px green solid;
        display:flex;
        flex-direction:column;
        align-items:flex-end;
        >div{
          // border:5px blue solid;
          width:17vw;
          height:100%;
        }
      }
    }
    .hm3-box1-content2{
      // border:4px red solid;
      // box-sizing:border-box;
      width: 85vw;
      margin-top:4vw;
      margin-bottom:10vw;
      >img{
        width:100%;
      }
    }
    // padding:0% 10%;
    // padding
    // > img {
    //   width: 130%;
    // }
  }

  .hm3-footer {
    // border:4px red solid;
    box-sizing: border-box;
    // position: absolute;
    // bottom:0;
    // left:0;
    // right:0;
    background: #0032A0;
    padding: 5% 6% 8% 6%;
    color: rgba(255, 255, 255, 0.6);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    // color: rgba(255, 255, 255, 0.6);
    // border:1px red solid;
  }
}