@import "styles/_variables";
@import "styles/_mixins";

.vision {
  @include subComponentsStyle();
  display: flex;
  padding-top: 20vh;
  align-items: flex-start;
  // justify-content: center;
  // border: 1px blue solid;

  .contents {
    width: 90%;
    // border: 1px red solid;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-child(1) {
        h2 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 1.5vw;

          text-align: center;

          color: #0032A0;
        }

        h3 {
          font-family: "Noto Sans KR";
          font-style: normal;
          font-weight: 300;
          font-size: 1vw;
          text-align: center;

          color: #333333;
        }
      }

      &:nth-child(2) {
        position: relative;
        align-self: start;
        display: grid;
        grid-template-rows: repeat(6, 1fr);
        grid-template-columns: repeat(6, 1fr);

        img {
          grid-row: 1/-1;
          grid-column: 1/-1;
          align-self: center;
          justify-self: center;
        }
      }
    }
  }
}