@import "styles/_variables";
@import "styles/_mixins";

.hm3-body-wrapper {
  position: relative;
  // border:4px blue solid;
  box-sizing: border-box;

  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .hm3-box1 {
    margin: 10% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #333333;
    font-size: 16px;
  }

  .hm3-box2 {
    // border: 3px red solid;
    // box-sizing: border-box;
    background: #9C9C9C;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >img {
      width: 100%;
    }

    .hm3-box2-text1 {
      // border:1px red solid;
      position: absolute;
      top: 5%;
      left: 10%;
      right: 10%;

      >div:nth-child(1) {
        // border:1px red solid;
        display: flex;

        >div {
          margin-left: 5%;

          >div:nth-child(1) {
            font-family: "Noto Sans KR";
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            color: #333333;
          }

          >div:nth-child(2) {
            font-family: "Montserrat";
            font-weight: bold;
            font-size: 26px;
            color: #1976D3;
          }
        }
      }

      >div:nth-child(2) {
        border: 1px #1976D3 solid;
        border-radius: 50px;
        font-family: "Montserrat";
        display: flex;
        justify-content: center;
        height: 38px;
        color: #1976D3;
        margin-top: 5%;
        width: 100%;
        overflow: hidden;

        // padding:0 5%;
        >div {
          font-size: 13px;
          white-space: nowrap;
          // border:1px red solid;
          // border-radius: 50px;
          // height:120%;
          // margin-right: 5%;
          // width:40%;
          display: flex;
          justify-content: center;
          align-items: center;

          // border: 1px red solid;
          width: 100%;

          &:hover {
            background-color: #0032A0;
            color: white;
          }
        }
      }

      // border:1px red solid;
    }

    .hm3-box2-text2 {
      position: absolute;
      top: 88%;
      left: 10%;
      // border:1px blue solid;
      width: 80%;

      >div:nth-child(1) {
        font-size: 13px;
        // border: 1px red solid;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // width:90%;
        font-family: "Noto Sans KR";
        font-style: normal;
        // font-weight: 300;
        // font-size: 11px;
        color: #1976D3;

        >img {
          margin-left: 10px;
          width: 20px;
          height: 20px;
        }

        margin-bottom:40px;
      }

      >div:nth-child(2) {
        // padding-right:20px;
        // border:1px red solid;
        line-height: 20px;
        margin-top: 15px;
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        color: #303030;
      }
    }
  }

  .hm3-box3 {
    margin: 5% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: #333333;
  }

  .hm3-box4 {
    margin: 10% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;

    color: #333333;
  }

  .hm3-box5 {
    margin: 5% 6% 5% 6%;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    color: #333333;
  }

  .hm3-footer {
    // border: 1px red solid;
    // position: absolute;
    // bottom:0;
    // left:0;
    // right:0;
    background: #9C9C9C;
    padding: 5% 6% 8% 6%;
    color: rgba(255, 255, 255, 0.6);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    // color: rgba(255, 255, 255, 0.6);
    // border:1px red solid;
  }
}