@import "styles/_variables";
@import "styles/_mixins";

.hm1 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // position: absolute;
  // top: 0;
  // left: 0;
  // border: 4px red solid;
  box-sizing: border-box;
  // scroll-snap-type: y proximity;
  /* snap area의 가운데 스냅, 다음 이미지의 2번째 snap area에 해당 */

  // min-height: 812px;
  width: 100%;
  // height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  // display: flex;
  flex-direction: column;

  background-image: url("../../media/MOBILE/home-mobile-bg.png");
  background-size: cover;
  overflow: scroll;
  @include scroll-style-hidden();

  >img {
    width: 22vw;
    position: absolute;
    top: 5%;
    left: 10%;
  }

  >span {
    color: white;
    // color: red;
    font-size: 8vw;
    position: absolute;
    font-family: "Montserrat";
    font-weight: bold;
    // bottom: 90vh;
    bottom: calc(var(--vh, 1vh) * 35);
    // bottom: 0%;
    left: 10%;
    // color:red;
    // z-index: 3;
  }

  overflow-y: auto;
  overflow-x: hidden;
  // @include scroll-style-hidden();

  // border:1px red solid;
  // box-sizing:border-box;
  .hm2 {
    // border: 4px blue solid;
    box-sizing: border-box;
    overflow: scroll;
    @include scroll-style-hidden();
    // scroll-snap-align: start;
    // margin-top: 10vh;
    margin-top: calc(var(--vh, 1vh) * 70);
    // margin-top: max(100px, 400px);
    height: calc(100% + 20px);
    border-radius: 20px 20px 0px 0px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: white;
    position: relative;

    .hm2-handle {
      height: 40px;
      width: 100%;
      position: absolute;
      top: 0px;
    }

    .hm2-body {
      // scroll-snap-type: y mandatory;
      // border:4px yellow solid;
      box-sizing: border-box;
      position: absolute;
      // top: 40px;
      padding-top:40px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;

      /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      box-sizing: border-box;

      .hm3-cards-container {
        // border:3px red solid;
        box-sizing: border-box;
        background-image: url("../../media/MOBILE/line2.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: 20vw;
        scroll-snap-type: x mandatory;
        box-sizing: border-box;
        width: 100%;
        // height: 45%;
        height: 80vw;
        white-space: nowrap;
        overflow: scroll;
        @include scroll-style-hidden();

        text-align: center;
        font-size: 10vw;

        >img {
          // border: 1px blue solid;
          -webkit-user-select: none;
          -webkit-touch-callout: none;
          user-select: none;
          scroll-snap-align: center;
          position: relative;
          height: 90%;
          box-sizing: border-box;
          filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.1));
          padding: 5%;
          padding-top: 0;
        }
      }

      .hm3-main-container {
        // border:3px blue solid;
        box-sizing: border-box;
        width: 100%;
        height: 103%;
        // overflow: hidden;
        overflow: scroll;
        @include scroll-style-hidden();

        .hm3-menu-items {
          // border:3px red solid;
          box-sizing: border-box;
          height: 6%;
          margin: 0px 6%;
          border-bottom: 0.5px #9b9b9b solid;
          display: flex;
          justify-content: space-between;
          align-items:flex-end;

          >div {
            -webkit-touch-callout: none;
            /* iOS Safari */
            -webkit-user-select: none;
            /* Safari */
            -khtml-user-select: none;
            /* Konqueror HTML */
            -moz-user-select: none;
            /* Old versions of Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            user-select: none;
            /* Non-prefixed version, currently */
            cursor: pointer;
            font-family: "Montserrat", sans-serif;
            color: #9b9b9b;
            padding: 0vw 0 0vw 0;
            padding-bottom:10px;
            border-bottom: 2px transparent solid;

            &.active {
              color: black;
              border-bottom: 2px black solid;
              font-weight: 500;
              // box-sizing: border-box;
            }

            // &:hover {
            //   font-weight: bold;
            //   color: black;
            //   border-bottom: 2px black solid;
            // }
          }
        }

        .hm3-menu-subitems {
          height: 8%;
          margin: 0px 6%;
          // border:2px blue solid;
          box-sizing:border-box;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top:17px;

          >div {
            -webkit-touch-callout: none;
            /* iOS Safari */
            -webkit-user-select: none;
            /* Safari */
            -khtml-user-select: none;
            /* Konqueror HTML */
            -moz-user-select: none;
            /* Old versions of Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            user-select: none;
            /* Non-prefixed version, currently */
            cursor: pointer;
            font-family: "Noto Sans KR", sans-serif;
            color: #9b9b9b;
            // border: 1px orange solid;
            white-space: nowrap;
            margin: 0vw 8vw 0vw 0;
            display: flex;

            >span:nth-child(1) {
              display: flex;
              align-items: flex-end;
            }

            >span:nth-child(2) {
              visibility: hidden;
              font-size: 2vw;
              color: red;
            }

            &.active {
              color: black;
              font-weight: 500;


              >span:nth-child(2) {
                visibility: visible;
                font-size: 2vw;
                color: red;
              }
            }
          }
        }

        .hm3-body-container {
          // border:4px red solid;
          box-sizing: border-box;
          height: 86%;
          overflow: scroll;
          @include scroll-style-hidden();
          position: relative;
        }
      }
    }
  }
}

.hm3-box3 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // border: 5px blue solid;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  // z-index: 3;
  .hm3-box3-auth_process {
    width: 70vw;
    height: 40vw;
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >div {
      // border: 1px red solid;

    }

    >input {
      display: flex;
      align-items: flex-end;
      border: 0px red solid;
      text-align: center;
      width: 80%;
      height: 40%;
      caret-color: transparent;
    }

    >input:focus {
      outline: none;
    }

    >input[type="password"] {
      color: #0032A0;
      font-size: 12vw;
      -webkit-text-security: disc;

      &::placeholder {
        -webkit-text-security: disc;
      }
    }
  }

  .hm3-box3-confirm_process {
    width: 85vw;
    height: 40vw;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >div {
      // border:1px red solid;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;

      color: #333333;

      &:nth-child(1) {
        height: 60%;
        width: 70%;
      }

      &:nth-child(2) {
        height: 30%;
        width: 100%;
        border-top: 1px solid #CFCFCF;

        >span {
          width: 70px;
          height: 23px;
          font-weight: bold;
          // border:1px red solid;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0% 4px;

          &:nth-child(1) {
            background: #0032A0;
            color: white;
          }

          &:nth-child(2) {
            background: #E1E1E1;
            color: black;
          }
        }
      }
    }
  }
}