%menuButton {
  display: block;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.5vw;
  height: 1.5vw;
  margin: 1vw;
  padding-left: 1vw;
  color: #121212;
  user-select: none;
  cursor: pointer;
  opacity: 0.5;
  white-space: nowrap;
}
