@import "styles/_mixins";

.backgroundvideo {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  color: white;
  > video {
    min-width:100%;
    min-height:100%;
  }

  .text-bg {
    position: absolute;
    bottom: 10%;
    width: 100vw;
    font-family: "Montserrat";
    font-style: bold;
    font-weight: 900;
    padding-left: 8vw;
    font-size: 5vw;
    line-height: 6vw;
    overflow: hidden;
    color: inherit;
    &.activated {
      animation: textBg-hidden 4s linear forwards;
    }
    @keyframes textBg-hidden {
      to {
        opacity: 0;
      }
    }
  }
  .text-bg2 {
    position: absolute;
    bottom: 40%;
    width: 100vw;
    font-family: "Montserrat";
    font-style: bold;
    font-weight: 900;
    padding-left: 48vw;
    font-size: 5vw;
    line-height: 6vw;
    overflow: hidden;
    color: inherit;
    &.activated {
      animation: textBg-hidden 4s linear forwards;
    }
    @keyframes textBg-hidden {
      to {
        opacity: 0;
      }
    }
  }

  &.turnColorBlack {
    @include animation(0s, 3s, 1, forwards, turnColorBlack);
  }

  video {
    &.fadeout {
      @include animation(0s, 3s, 1, forwards, fadeout);
    }
  }
}
