@import "styles/_variables";
@import "styles/_mixins";

.hm3-body-wrapper {
  position: relative;
  // border:4px red solid;
  box-sizing: border-box;

  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  display:flex;
  flex-direction:column;
  justify-content:space-between;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .hm3-box3 {
    // border:4px green solid;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    >img {
      width: 85%;
      margin: 10% 0 20% 0;
    }
  }

  .hm3-footer {
    // position: absolute;
    // bottom:0;
    // left:0;
    // right:0;
    background: #0032A0;
    padding: 5% 6% 8% 6%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    color: rgba(255, 255, 255, 0.6);
    // border:1px red solid;
  }
}