@import "styles/_variables";
@import "styles/_mixins";

.outsourcing {
  @include subComponentsStyle();
  padding-bottom: 35px;

  .service-filter {
    height: 8vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    justify-items: end;
    align-items: center;

    ul {
      width: 50%;
      grid-column: 1/2;
      grid-row: 1/-1;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 0px 1.2vw 0px 0px;
        font-family: "Montserrat";
        font-weight: 900;
        font-size: 1vw;
        color: #121212;
        opacity: 0.4;

        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
    }
  }

  .outsourcing-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    gap: 35px;
    justify-items: center;
    @include animation(0s, 1s, 1, forwards, slideup_fadein);

    img {
      width: 50%;
      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));

      &:nth-child(odd) {
        justify-self: end;
      }

      &:nth-child(even) {
        justify-self: start;
      }
    }
  }
}
